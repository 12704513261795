:root {
    --font-family-main: 'Open Sans', sans-serif;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
    --font-size-s: 16px;
    --font-size-m: 18px;
    --font-size-l: 22px;
    --font-size-xl: 26px;
    --font-size-title: 32px;
    --font-line-m: 24px;
    --font-weight-m: 400;
    --font-weight-l: 600;
    --font-weight-xl: 900;
    --font-color: #fff;
    --font-color-kp: #f50;
    --font-color-imdb: #f5c518;
    --font-color-optional: #9b9b9b;
    --background-color: #000;
    --background-color-fade: #000000ab;
    --background-color-widgets: #930215;
    --background-color-assets: #93021571;
    --background-color-green: #006e00;
    --background-color-yellow: #d8d800;
    --background-color-red: #b50000;
    --background-color-skeleton: #7c7c7c;
}
