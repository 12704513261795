.modalSearch {
    &__list {
        position: absolute;
        top: 85px;
        right: 0;
        left: 0;
        background-color: var(--background-color-assets);
        border-radius: 10px;
        padding: 20px;
        height: 500px;
        overflow: scroll;
        box-shadow: 0 0 70px 40px #000;

        &_left {
            width: 150px;
            height: 220px;
            border-radius: 10px;
            overflow: hidden;
            margin-top: auto;
            transition: transform 0.15s linear;

            img {
                transition: opacity 0.3s, transform 0.15s linear !important;
                width: 150px;
                height: 220px;
            }
        }

        &_item {
            display: flex;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            &:hover {
                .modalSearch__list_left img {
                    transform: scale(1.05);
                }
            }
        }

        &_right {
            margin-left: 20px;
            max-width: 340px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &_name {
            font-size: var(--font-size-l);
            font-weight: var(--font-weight-l);
            margin-bottom: 15px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }

        &_assets {
            margin-bottom: 5px;

            &_last {
                margin-bottom: 15px;
            }
        }

        &_year {
            color: var(--font-color-optional);
            font-weight: var(--font-weight-l);
            text-align: right;
            margin-top: auto;
        }

        &_rating {
            margin-bottom: 5px;
        }
    }

    &__info {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-l);
    }
}

.open {
    display: block;
}

.close {
    display: none;
}
