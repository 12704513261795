* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

input,
button,
textarea,
select {
    margin: 0;
    font: inherit;
    outline: none;
    border: none;
    color: inherit;
    background-color: transparent;
}

button {
    cursor: pointer;
}

iframe {
    border: none;
}
