.header {
    position: absolute;
    z-index: 5;
    height: 150px;
    top: 0;
    right: 0;
    left: 0;

    &__inner {
        position: relative;
        padding: 20px 50px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &_logo {
            position: absolute;
            display: block;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);

            img {
                width: 150px;
                height: 105px;
            }
        }
    }
}
