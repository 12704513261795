.assetsList {
    display: flex;
    flex-wrap: wrap;
    min-width: 300px;

    &__item {
        display: flex;
        align-items: center;
        background-color: var(--background-color-assets);
        border-radius: 10px;
        padding: 10px 25px;
        margin-bottom: 5px;

        &:not(:last-of-type) {
            margin-right: 20px;
        }

        &_img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }
}

.search {
    .assetsList {
        &__item {
            padding: 5px 15px;

            &:not(:last-of-type) {
                margin-right: 10px;
            }
        }
    }
}
